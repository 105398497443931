import React, {useEffect, useRef} from 'react'
import PropTypes from 'prop-types'
import {List} from 'antd'
import {connectInfiniteHits} from 'react-instantsearch-dom'

import Hit from 'molecules/Hit'

/**
 * Render the InfiniteHits component.
 * @see                                {@link https://www.algolia.com/doc/api-reference/widgets/infinite-hits/react/}
 * @author                             Content Pilot
 * @param  {object}   props            The function attributes as props.
 * @param  {Array}    props.hits       The hits that matched the search request.
 * @param  {boolean}  props.hasMore    Whether there are more pages to load.
 * @param  {Function} props.refineNext The component class name.
 * @return {Element}                   The InfiniteHits component.
 */
function InfiniteHits({hits, hasMore, refineNext}) {
  const sentinel = useRef(null)

  /**
   * IntersectionObserver callback function.
   * @param {Array} entries list of entries received by the callback
   */
  function onSentinelIntersection(entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting && hasMore) {
        refineNext()
      }
    })
  }

  useEffect(() => {
    const observer = new IntersectionObserver(onSentinelIntersection, {})
    observer.observe(sentinel.current)

    return () => observer.disconnect()
  })

  return (
    <List dataSource={hits} renderItem={(hit) => Hit(hit)}>
      <li ref={sentinel} />
    </List>
  )
}

InfiniteHits.propTypes = {
  hits: PropTypes.arrayOf(PropTypes.object).isRequired,
  hasMore: PropTypes.bool.isRequired,
  refineNext: PropTypes.func.isRequired
}

export default connectInfiniteHits(InfiniteHits)
