import React from 'react'
import {Input} from 'antd'
import PropTypes from 'prop-types'
import {connectSearchBox} from 'react-instantsearch-dom'

import {input} from './SearchBox.module.scss'

/**
 * Render the SearchBox component.
 * @see                                       {@link https://www.algolia.com/doc/api-reference/widgets/search-box/react/}
 * @author                                    Content Pilot
 * @param  {object}   props                   The component attributes as props.
 * @param  {string}   props.currentRefinement The currently applied refinement.
 * @param  {Function} props.refine            Selects a refinement.
 * @return {Element}                          The SearchBox component.
 */
function CustomSearchBox({currentRefinement, refine}) {
  return (
    <Input
      placeholder="Keyword"
      className={input}
      onChange={(event) => refine(event.currentTarget.value)}
      size="large"
      value={currentRefinement}
      title="Keyword"
    />
  )
}

CustomSearchBox.propTypes = {
  currentRefinement: PropTypes.string,
  refine: PropTypes.func
}

const SearchBox = connectSearchBox(CustomSearchBox)

export default SearchBox
