/* eslint-disable func-style */
import React, {useEffect, useState, useRef} from 'react'
import algoliaClient from 'algoliasearch/lite'
import {useLocation} from '@reach/router'
import {InstantSearch, Configure} from 'react-instantsearch-dom'
import qs from 'qs'

import InfiniteHits from '../InfiniteHits'
import Main from 'organisms/Main'
import SearchTitle from 'atoms/SearchTitle'

const DEBOUNCE_TIME = 700

/**
 * Instances the Algolia search.
 */
const searchClient = algoliaClient(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
)

/**
 * Create the querystring form the state.
 * @param  {string} state The search state.
 * @return {string}       The search URL.
 */
const createURL = (state) => {
  return `?query=${state.query}`
}

/**
 * Build the url with the location and the querystring.
 * @param  {string} location    The search location.
 * @param  {Array}  searchState The search state object.
 * @return {string}             The search state.
 */
const searchStateToUrl = (location, searchState) => {
  return searchState ? `${location.pathname}${createURL(searchState)}` : ''
}

/**
 * Transform the URL on the current state.
 * @param  {string} location The search location.
 * @return {string}          The search URL.
 */
const urlToSearchState = (location) => qs.parse(location.search.slice(1))

/**
 * Render the InstantSearch component.
 * @see     {@link https://www.algolia.com/doc/api-reference/widgets/instantsearch/react/}
 * @author  Content Pilot
 * @return {Element} The InstantSearch component.
 */
export default function Search() {
  const location = useLocation()

  const [searchState, setSearchState] = useState(urlToSearchState(location))

  const setStateId = useRef()

  useEffect(() => {
    const nextSearchState = urlToSearchState(location)

    if (JSON.stringify(searchState) !== JSON.stringify(nextSearchState)) {
      setSearchState(nextSearchState)
    }
    // eslint-disable-next-line
  }, [location])

  /**
   * Set the new url built with the current state.
   * @param {Array} nextSearchState The search state object.
   */
  const onSearchStateChange = (nextSearchState) => {
    clearTimeout(setStateId.current)

    setStateId.current = setTimeout(() => {
      searchStateToUrl(location, nextSearchState)
    }, DEBOUNCE_TIME)

    setSearchState(nextSearchState)
  }

  return (
    <InstantSearch
      indexName={process.env.GATSBY_ALGOLIA_INDEX}
      searchClient={searchClient}
      searchState={searchState}
      createURL={createURL}
      onSearchStateChange={onSearchStateChange}
      style={{marginTop: 50}}
    >
      <Configure hitsPerPage={9} distinct facetingAfterDistinct />
      <SearchTitle />
      <Main>
        <InfiniteHits />
      </Main>
    </InstantSearch>
  )
}
