import React from 'react'
import PropTypes from 'prop-types'
import {Link} from 'gatsby'
import {List} from 'antd'
import {Highlight, Snippet} from 'react-instantsearch-dom'

import {item, link} from './Hit.module.scss'

/**
 * Render the Hit component.
 * @see                 {@link https://www.algolia.com/doc/api-reference/widgets/infinite-hits/react/}
 * @author              Content Pilot
 * @param  {object}  hit The hit object.
 * @return {Element}     The Hit component.
 */
export default function Hit(hit) {
  const {objectID, relative_permalink} = hit

  return (
    <List.Item key={objectID} className={item}>
      <Link to={relative_permalink} className={link}>
        <h2>
          <Highlight attribute="post_title" hit={hit} tagName="mark" />
        </h2>
      </Link>
      <Snippet hit={hit} attribute="content" tagName="mark" />
    </List.Item>
  )
}

Hit.prototype = {
  hit: PropTypes.array.isRequired
}
