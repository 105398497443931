import React from 'react'

import Content from 'organisms/Content'

import InstantSearch from 'organisms/InstantSearch'
import SEO from 'molecules/Seo'
import MenuSecondary from 'molecules/MenuSecondary'

/**
 * Search Page
 * @return {Element} The Search page.
 */
export default function SearchPage() {
  return (
    <Content>
      <SEO title="Global Search" />
      <MenuSecondary />
      <InstantSearch />
    </Content>
  )
}
