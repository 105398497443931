import React, {Fragment} from 'react'
import PropTypes from 'prop-types'
import {useStaticQuery, graphql, Link} from 'gatsby'
import {useLocation} from '@reach/router'
import {List} from 'antd'

const converter = require('number-to-words')

import {menu, active, item, circle, label} from './MenuSecondary.module.scss'

/**
 * Render the MenuSecondary component.
 * @see                       {@link https://ant.design/components/list/}
 * @author                    Content Pilot
 * @param  {string}  className Optional class name.
 * @return {Element}           The MenuSecondary component.
 */
export default function MenuSecondary({className}) {
  const data = useStaticQuery(
    graphql`
      query {
        allWpMenuItem(
          filter: {locations: {eq: SECONDARY}, parentDatabaseId: {eq: 0}}
        ) {
          nodes {
            label
            path
          }
        }
      }
    `
  )

  const {pathname} = useLocation()

  const menuItems = data?.allWpMenuItem?.nodes ?? null

  return (
    <Fragment>
      {menuItems && (
        <List
          className={className ?? menu}
          dataSource={menuItems}
          renderItem={(menuItem, key) => {
            const {path, label: itemLabel} = menuItem

            const classes = pathname.includes(path) ? `${item} ${active}` : item

            return (
              <List.Item className={classes}>
                <Link to={path}>
                  <div className={circle}>{converter.toWords(key + 1)}</div>
                  <span className={label}>{itemLabel}</span>
                </Link>
              </List.Item>
            )
          }}
        ></List>
      )}
    </Fragment>
  )
}

MenuSecondary.propTypes = {
  className: PropTypes.string
}
