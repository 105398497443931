// extracted by mini-css-extract-plugin
export var active = "MenuSecondary-module--active--dbfd8";
export var bounce = "MenuSecondary-module--bounce--9aae8";
export var circle = "MenuSecondary-module--circle--2d95c";
export var dotMoveLeft = "MenuSecondary-module--dot-move-left--455fc";
export var dotMoveRight = "MenuSecondary-module--dot-move-right--8cc0d";
export var gradient = "MenuSecondary-module--gradient--18f7a";
export var item = "MenuSecondary-module--item--b203d";
export var label = "MenuSecondary-module--label--ae925";
export var menu = "MenuSecondary-module--menu--cfd30";
export var separatorWidth = "MenuSecondary-module--separator-width--28b84";