import React from 'react'
import {Typography} from 'antd'

import SearchBox from './../SearchBox'

const {Title} = Typography

import {container, title} from './SearchTitle.module.scss'

/**
 * Render the SearchTitle component.
 * @author  Content Pilot
 * @return {Element} The SearchTitle component.
 */
export default function SearchTitle() {
  return (
    <div className={container}>
      <Title className={title} level={1}>
        Search
      </Title>
      <SearchBox />
    </div>
  )
}
